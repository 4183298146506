import React from 'react';
import logo from './logo.svg';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from './lib/Theme';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import { Homepage } from './containers/Homepage';
import { Chat } from './containers/Chat';
import { Like } from './containers/Like';
import { LikeDetails } from './containers/LikeDetails';
import { MyProfile } from './containers/MyProfile';
import { NearMe } from './containers/NearMe';
import { StartMyTrip } from './containers/StartMyTrip';
import { Travel } from './containers/Travel';
import { GlobalStyles } from './lib/Theme/GlobalStyles';

 
function App() {
  return (
    <div className="App">
    <ThemeProvider>
      <GlobalStyles/>
      <Navbar />
        <Router>
          <Switch>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/chat" component={Chat} />
            <Route exact path="/like" component={Like} />
            <Route exact path="/likedetails" component={LikeDetails} />
            <Route exact path="/myprofile" component={MyProfile} />
            <Route exact path="/nearme" component={NearMe} />
            <Route exact path="/startmytrip" component={StartMyTrip} />
            <Route exact path="/travel" component={Travel} />
           </Switch>
        </Router>

        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
