import React from 'react';
import { ProgressiveImage } from '../../components/ProgressiveImage';

export class NearMe extends React.Component {

	render() {
		return <section>

			<div className="innerpage-wrapper">
				<div className="container">
					<div className="row innrpage-bg mb30">
						<div className="col-lg-12">

							<div id="wrapper">
								<div id="sidebar-wrapper">
									<ul className="sidebar-nav">
										<li className="sidebar-brand">
											<a href="/MyProfile">
												<img id="profile-img" src="http://emilcarlsson.se/assets/mikeross.png" className="online" alt="" /> My Profile
                                            </a>
										</li>
										<li>
											<a href="/NearMe">People Nearby </a>
										</li>
										<li>
											<a href="/Like">Like</a>
										</li>
										<li>
											<a href="/Chat">Chat</a>
										</li>
										<li>
											<a href="/Travel">Travel</a>
										</li>
										<li>
											<a href="#">Notification</a>
										</li>

									</ul>
								</div>

								<div id="page-content-wrapper">
									<div className="">
										<div className="row">
											<div className="col-lg-12">
												<a href="#menu-toggle" className="btn btn-default com-btn togglemenu" id="menu-toggle">
													<i className="fa fa-bars" aria-hidden="true"></i>
												</a>
												<div className="col-lg-12 mt10 mb10">
													<div className="nerame-heading">
														<h5>People Nearby
															  <div className="pull-right">
																<div className="dropdown">
																	<button className="btn btn-default com-btn btn-sm dropdown-toggle" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
																		City
																			</button>
																	<div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenu2">
																		<button className="dropdown-item" type="button">Mumbai</button>
																		<button className="dropdown-item" type="button">Delhi</button>
																		<button className="dropdown-item" type="button">Punjab</button>
																	</div>
																</div>
															</div>
														</h5>
													</div>
												</div>
												<div id="frame">
													<div id="sidepanel">




														<div id="contacts">
															<ul>
																<li className="contact">
																	<div className="wrap">
																		<span className="contact-status online"></span>
																		<img src="http://emilcarlsson.se/assets/louislitt.png" alt="" />
																		<div className="meta">
																			<p className="name">Louis Litt</p>
																			<p className="preview">You just got LITT up, Mike.</p>
																		</div>
																	</div>
																</li>
																<li className="contact active">
																	<div className="wrap">
																		<span className="contact-status busy"></span>
																		<img src="http://emilcarlsson.se/assets/harveyspecter.png" alt="" />
																		<div className="meta">
																			<p className="name">Harvey Specter</p>
																			<p className="preview">Wrong. You take the gun, or you pull out a bigger one. Or, you call their bluff. Or, you do any one of a hundred and forty six other things.</p>
																		</div>
																	</div>
																</li>
																<li className="contact">
																	<div className="wrap">
																		<span className="contact-status away"></span>
																		<img src="http://emilcarlsson.se/assets/rachelzane.png" alt="" />
																		<div className="meta">
																			<p className="name">Rachel Zane</p>
																			<p className="preview">I was thinking that we could have chicken tonight, sounds good?</p>
																		</div>
																	</div>
																</li>
																<li className="contact">
																	<div className="wrap">
																		<span className="contact-status online"></span>
																		<img src="http://emilcarlsson.se/assets/donnapaulsen.png" alt="" />
																		<div className="meta">
																			<p className="name">Donna Paulsen</p>
																			<p className="preview">Mike, I know everything! I'm Donna..</p>
																		</div>
																	</div>
																</li>
																<li className="contact">
																	<div className="wrap">
																		<span className="contact-status busy"></span>
																		<img src="http://emilcarlsson.se/assets/jessicapearson.png" alt="" />
																		<div className="meta">
																			<p className="name">Jessica Pearson</p>
																			<p className="preview">Have you finished the draft on the Hinsenburg deal?</p>
																		</div>
																	</div>
																</li>
																<li className="contact">
																	<div className="wrap">
																		<span className="contact-status"></span>
																		<img src="http://emilcarlsson.se/assets/haroldgunderson.png" alt="" />
																		<div className="meta">
																			<p className="name">Harold Gunderson</p>
																			<p className="preview">Thanks Mike! :)</p>
																		</div>
																	</div>
																</li>
																<li className="contact">
																	<div className="wrap">
																		<span className="contact-status"></span>
																		<img src="http://emilcarlsson.se/assets/danielhardman.png" alt="" />
																		<div className="meta">
																			<p className="name">Daniel Hardman</p>
																			<p className="preview">We'll meet again, Mike. Tell Jessica I said 'Hi'.</p>
																		</div>
																	</div>
																</li>
																<li className="contact">
																	<div className="wrap">
																		<span className="contact-status busy"></span>
																		<img src="http://emilcarlsson.se/assets/katrinabennett.png" alt="" />
																		<div className="meta">
																			<p className="name">Katrina Bennett</p>
																			<p className="preview">I've sent you the files for the Garrett trial.</p>
																		</div>
																	</div>
																</li>
																<li className="contact">
																	<div className="wrap">
																		<span className="contact-status"></span>
																		<img src="http://emilcarlsson.se/assets/charlesforstman.png" alt="" />
																		<div className="meta">
																			<p className="name">Charles Forstman</p>
																			<p className="preview">Mike, this isn't over.</p>
																		</div>
																	</div>
																</li>
																<li className="contact">
																	<div className="wrap">
																		<span className="contact-status"></span>
																		<img src="http://emilcarlsson.se/assets/jonathansidwell.png" alt="" />
																		<div className="meta">
																			<p className="name">Jonathan Sidwell</p>
																			<p className="preview"><span>You:</span> That's bullshit. This deal is solid.</p>
																		</div>
																	</div>
																</li>
															</ul>
														</div>
														<div id="bottom-bar" className="col-md-12">
															<p> <i className="fa fa-users" aria-hidden="true"></i>  + 10 more people Around you </p>

														</div>
													</div>
													<div className="content near-me-map">
														<img src="img/nearme.png" alt="" className="img-responsive " />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>


						</div>

					</div>

				</div>
			</div>


		</section>;
	}
}