import React from 'react';
import { ProgressiveImage } from '../../components/ProgressiveImage';

export class Homepage extends React.Component {

	render() {
		return <section>
			<div className="banner ">
				<div className="container">
					<div className="row">
						<div className="col-md-6">
							<h2 className="mt30 text-white">Connect people & New business
                              Opportunities around the world</h2>
							  <a className="btn btn-lg btn-success big-btn android-btn mt20" href="https://play.google.com/store/apps/details?id=com.eraswaponeapp" target="_blank">
  				                   <img width="80" className="pull-left" src="/img/google_play_04.png"/>
									<div className="btn-text"><small>Available on</small><br/><strong>Google Play</strong></div>
							</a>
              
						</div>
						<div className="col-md-6 tree">
							<img src="/img/banner-right-pic.png" alt="" className="img-fluid bounce-1" />

						</div>
					</div>
				</div>
			</div>
			<div className="features-wrapper">
				<div className="container">
					<div className="row features-sec">
					     <div className="col-lg-6">
								<div className="features-box">
								     <img src="/img/fet1.png" alt="" className="img-fluid bounce-1" />
									 <h3 className="theme-text">Decentralized Environment</h3>
									 <p className="desc mt20">Date Swappers enables users to control their own data.
										Since it is powered on the blockchain, there are no central
										authorities that controls your data, on this decentralized
										global network</p>
                                </div>
							</div>
							<div className="col-lg-6 ">
							    
							</div> 
					</div>
					<div className="row features-sec">
					       <div className="col-lg-6 ">
							    
							</div> 
							<div className="col-lg-6">
								<div className="features-box">
								     <img src="/img/fet2.png" alt="" className="img-fluid bounce-1" />
									 <h3 className="theme-text">Face Verification & Profile Check </h3>
									 <p className="desc mt20">To create a healthy dating environment – Date Swappers
											removes scammers and bots using face verification test,
											and general profile verifications so genuine users can
											experience date swappers, conveniently
										</p>
                                </div>
							</div>
					</div>
					<div className="row features-sec">
					     <div className="col-lg-6">
								<div className="features-box">
								     <img src="/img/fet3.png" alt="" className="img-fluid bounce-1" />
									 <h3 className="theme-text">Nearby – Users Detection</h3>
									 <p className="desc mt20">Explore your neighborhood to meet new people from
										your area or region. Date Swappers has a unique
										element, where it access your approximate location to
										provide a list of nearby people</p>
                                </div>
							</div>
							<div className="col-lg-6 ">
							    
							</div> 
					</div>
					<div className="row features-sec">
					       <div className="col-lg-6 ">
							    
							</div> 
							<div className="col-lg-6">
								<div className="features-box">
								     <img src="/img/fet4.png" alt="" className="img-fluid bounce-1" />
									 <h3 className="theme-text">Safeguard Your Date</h3>
									 <p className="desc mt20">Ensure you or your partner doesn’t miss the date, with
										“Token Securities”.
										Both parties will put a specific number of tokens at stake,
										and if anyone doesn’t show up, the one who showed up
										gets rewarded, and if the date went successful, both
										parties will be rewarded positively
										</p>
                                </div>
							</div>
					</div>
					<div className="row features-sec">
					     <div className="col-lg-6">
								<div className="features-box">
								     <img src="/img/fet5.png" alt="" className="img-fluid bounce-1" />
									 <h3 className="theme-text">Travel – Dating</h3>
									 <p className="desc mt20">If you are going out traveling and wish to take a date
										along, well Date Swappers can make it possible. All you
										have to do is use this feature and your nearby dates will
										get a notification, and then voila</p>
                                </div>
							</div>
							<div className="col-lg-6 ">
							    
							</div> 
					</div>
					<div className="row features-sec">
					       <div className="col-lg-6 ">
							    
							</div> 
							<div className="col-lg-6">
								<div className="features-box">
								     <img src="/img/fet6.png" alt="" className="img-fluid bounce-1" />
									 <h3 className="theme-text">Commit – Register </h3>
									 <p className="desc mt20">After a several meeting with your date, you may develop a
										bond with them and if you wish to make that bond official,
										Date Swappers offers Digital Certificates, for your happy
										marriage</p>
                                </div>
							</div>
					</div>
					<div className="row features-sec">
					       
							<div className="col-lg-12">
								<div className="features-box text-center">
								     <h1 className="theme-text">About us </h1>
									 <p className="desc mt20">Date Swappers is a modern, unique and decentralized dating app that connects you with the world and lets you meet new people and new
business opportunities, globally. With Date Swappers, individuals can set up a meeting time and place, while associating a certain security to
it. And, since Date Swappers is powered on the blockchain, there are not any central authorities that control or manipulate your data, which
means you are the rightful owner of your data, with the help of this decentralized approach.</p>
                                </div>
							</div>
					</div>
				</div>
			</div>


		</section>;
	}
}