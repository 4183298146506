import React from 'react';
import { ProgressiveImage } from '../../components/ProgressiveImage';

export class StartMyTrip extends React.Component {

	render() {
		return <section>

			<div className="innerpage-wrapper">
				<div className="container">
					<div className="row innrpage-bg mb30">
						<div className="col-lg-12">
							<div id="wrapper">
								<div id="sidebar-wrapper">
								<ul className="sidebar-nav">
										<li className="sidebar-brand">
											<a href="/MyProfile">
												<img id="profile-img" src="http://emilcarlsson.se/assets/mikeross.png" className="online" alt="" /> My Profile
                                            </a>
										</li>
										<li>
											<a href="/NearMe">People Nearby </a>
										</li>
										<li>
											<a href="/Like">Like</a>
										</li>
										<li>
											<a href="/Chat">Chat</a>
										</li>
										<li>
											<a href="/Travel">Travel</a>
										</li>
										<li>
											<a href="#">Notification</a>
										</li>

									</ul>
								</div>

								<div id="page-content-wrapper">
									<div className="container-fluid">
										<div className="row">
											<div className="col-lg-12 link-details">
												<a href="#menu-toggle" className="btn btn-default com-btn togglemenu" id="menu-toggle">
													<i className="fa fa-bars" aria-hidden="true"></i>
												</a>
												<div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-4 mx-auto mb40  ">
													<h2>My Profile</h2>
												</div>
												<div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-6 mx-auto mt40 mb40 card profile">

													<div className="profile-form text-center mt20">
														<form>
														    <div className="border-style-img ">
															   <img className="kycdapp-plus-Img" src="https://apis.timeswappers.com/./uploads/kycDappPhoto/1598962853468WhatsAppImage2020-08-25at3.43.15PM(2).jpeg" alt=""/>
															</div>

															<div className="form-group">
																<textarea></textarea>
																<label className="control-label">About</label><i className="bar"></i>
															</div>
															<div className="form-group">
																<input />
																<label className="control-label">Age</label><i className="bar"></i>
															</div>
															<div className="form-group">
																<select>
																	<option>Male</option>
																	<option>Female</option>
																</select>
																<label className="control-label">Genter</label><i className="bar"></i>
															</div>
															<div className="form-group">
																<input />
																<label className="control-label">Passion</label><i className="bar"></i>
															</div>
															<div className="form-group">
																<input />
																<label className="control-label">Profession</label><i className="bar"></i>
															</div>
															<div className="form-group">
																<input />
																<label className="control-label">KYC Hash </label><i className="bar"></i>
															</div>
															
															
														</form>
														<div className="mt10">
																<div className="pull-left"><small>Show My Swapperswall Ledge</small></div>
																<div className="pull-right">
																		<label className="switch">
																		<input type="checkbox" checked/>
																		<span className="slider round"></span>
																		</label>
																</div>
															</div>
														
														<div className="button-container clearfix mt40 mb40 	text-left">
															<button type="button" className="btn btn-default com-btn  btn-sm "><span>Done</span></button>
														</div>
														
													</div>



												
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>



						</div>
					</div>
				</div>
			</div>


		</section>;
	}
}