import React, { useContext } from 'react';
import { ThemeContext } from '../../lib/Theme/ThemeContext';

 
export const  Navbar = () => {
	const context = useContext(ThemeContext);
	console.log({context})
	return  (
    <nav className="navbar  navbar-default navbar-expand-md navbar-dark fixed-top bg-trasparent">
        <a className="navbar-brand" href="#">
          <img src="/img/logo.png" alt="" className="img-fluid bounce-1" width="90"/>
       </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <a className="nav-link" href="/">Home </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Chat">Get On 1 DAAP</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Features</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Contact Us</a>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right">
                  <li className="nav-item ">
                      <a className=" btn btn-default com-btn" href="/NearMe">Connect to Wallet  <span className="sr-only">(current)</span></a>
                    </li>
                    
            </ul>
          
        </div>
      </nav>
	);
}
