import React from 'react';
import { ProgressiveImage } from '../../components/ProgressiveImage';

export class LikeDetails extends React.Component {

	render() {
		return <section>

			<div className="innerpage-wrapper">
				<div className="container">
					<div className="row innrpage-bg mb30">
						<div className="col-lg-12">
							<div id="wrapper">
								<div id="sidebar-wrapper">
								<ul className="sidebar-nav">
										<li className="sidebar-brand">
											<a href="/MyProfile">
												<img id="profile-img" src="http://emilcarlsson.se/assets/mikeross.png" className="online" alt="" /> My Profile
                                            </a>
										</li>
										<li>
											<a href="/NearMe">People Nearby </a>
										</li>
										<li>
											<a href="/Like">Like</a>
										</li>
										<li>
											<a href="/Chat">Chat</a>
										</li>
										<li>
											<a href="/Travel">Travel</a>
										</li>
										<li>
											<a href="#">Notification</a>
										</li>

									</ul>
								</div>

								<div id="page-content-wrapper">
									<div className="container-fluid">
										<div className="row">
											<div className="col-lg-12 link-details">
												<a href="#menu-toggle" className="btn btn-default com-btn togglemenu" id="menu-toggle">
												 <i className="fa fa-bars" aria-hidden="true"></i>
												</a>

												<div className="col-12 col-sm-10 col-md-8 col-lg-7 col-xl-4 mx-auto mt40 mb40 card profile">
													<div className="img-box mt10">
														<img src="http://envato.jayasankarkr.in/code/profile/assets/img/profile-6.jpg" className="img-fluid"/>
								                    </div>
													<div className="profile-details text-center mt10">
															<h5>Izzy Williams, 25</h5>
															<small>Graphic Designer, 2miles away </small>
													</div>
													<div className="profile-interest mt30">
												     	<h6 className="font-weight-bold">Interests</h6>
												     	<span className="badge badge-primary">Art</span>
														<span className="badge badge-secondary">Poems</span>
														<span className="badge badge-success">Comics</span>
														<span className="badge badge-danger">Social Distancing</span>
														<span className="badge badge-warning">Sports</span>
														<span className="badge badge-info">Biking </span>
														
														<span className="badge badge-dark">Traveling</span>

													</div>
													<div className="profile-map mt20">
													    <h6 className="font-weight-bold">On Map</h6>
														<img src="img/map.jpg" className="img-fluid mt10"/>
								                    
       
													</div>
													<div className="profile-moment mt20">
													    <h6 className="font-weight-bold">Top Moments </h6>
														<div className="row mt10">
															<div className="col-md-6 col-sm-6 col-xs-6"><img className="img-fluid" src="http://2.bp.blogspot.com/-H6MAoWN-UIE/TuRwLbHRSWI/AAAAAAAABBk/89iiEulVsyg/s400/Free%2BNature%2BPhoto.jpg" /></div>
															<div className="col-md-6 col-sm-6 col-xs-6"><img className="img-fluid" src="http://2.bp.blogspot.com/-H6MAoWN-UIE/TuRwLbHRSWI/AAAAAAAABBk/89iiEulVsyg/s400/Free%2BNature%2BPhoto.jpg" /></div>
															<div className="col-md-6 col-sm-6 col-xs-6"><img className="img-fluid" src="http://2.bp.blogspot.com/-H6MAoWN-UIE/TuRwLbHRSWI/AAAAAAAABBk/89iiEulVsyg/s400/Free%2BNature%2BPhoto.jpg" /></div>
															<div className="col-md-6 col-sm-6 col-xs-6"><img className="img-fluid" src="http://2.bp.blogspot.com/-H6MAoWN-UIE/TuRwLbHRSWI/AAAAAAAABBk/89iiEulVsyg/s400/Free%2BNature%2BPhoto.jpg" /></div>
															<div className="col-md-6 col-sm-6 col-xs-6"><img className="img-fluid" src="http://2.bp.blogspot.com/-H6MAoWN-UIE/TuRwLbHRSWI/AAAAAAAABBk/89iiEulVsyg/s400/Free%2BNature%2BPhoto.jpg" /></div>
															<div className="col-md-6 col-sm-6 col-xs-6"><img className="img-fluid" src="http://2.bp.blogspot.com/-H6MAoWN-UIE/TuRwLbHRSWI/AAAAAAAABBk/89iiEulVsyg/s400/Free%2BNature%2BPhoto.jpg" /></div>
												     	</div>
													</div>
													<div className="swapperwall-view mt20">
														<p className="">View Profile on Swapperswall </p>
											             <a href="" className="btn btn-default btn-light mt0 mb30"><img src="img/swapperswall-btn.png" alt="" className="" width="150"/></a>
												 	</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>



						</div>
					</div>
				</div>
			</div>


		</section>;
	}
}