import React from 'react';
import { ProgressiveImage } from '../../components/ProgressiveImage';

export class Like extends React.Component {

	render() {
		return <section>

			<div className="innerpage-wrapper">
				<div className="container">
					<div className="row innrpage-bg mb30">
						<div className="col-lg-12">
							<div id="wrapper">
								<div id="sidebar-wrapper">
								<ul className="sidebar-nav">
										<li className="sidebar-brand">
											<a href="/MyProfile">
												<img id="profile-img" src="http://emilcarlsson.se/assets/mikeross.png" className="online" alt="" /> My Profile
                                            </a>
										</li>
										<li>
											<a href="/NearMe">People Nearby </a>
										</li>
										<li>
											<a href="/Like">Like</a>
										</li>
										<li>
											<a href="/Chat">Chat</a>
										</li>
										<li>
											<a href="/Travel">Travel</a>
										</li>
										<li>
											<a href="#">Notification</a>
										</li>

									</ul>
								</div>

								<div id="page-content-wrapper">
									<div className="container-fluid">
										<div className="row">
											<div className="col-lg-12">
											    <a href="#menu-toggle" className="btn btn-default com-btn togglemenu" id="menu-toggle">
													<i className="fa fa-bars" aria-hidden="true"></i>
												</a>
												<div className="tinder">
													<div className="tinder--status">
														<i className="fa fa-remove"></i>
														<i className="fa fa-heart"></i>
													</div>

													<div className="tinder--cards">
														<div className="tinder--card">
															<div className="profile-card-6">
																<img src="http://envato.jayasankarkr.in/code/profile/assets/img/profile-6.jpg" className="img img-responsive" />
																<div className="profile-name">JOHN
		                                                        <br />DOE</div>
																<div className="profile-position">Lorem Ipsum Donor</div>
																<div className="profile-overview">
																	<div className="profile-overview">
																		<div className="row text-center">
																			<div className="col-sm-4">
																				<h3>1</h3>
																				<p>Rank</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>50</h3>
																				<p>Matches</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>35</h3>
																				<p>Goals</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="tinder--card">
															
															<div className="profile-card-6">
																<img src="https://placeimg.com/600/300/animals" className="img img-responsive" />
																<div className="profile-name">JOHN
		                                                           <br />DOE</div>
																<div className="profile-position">Lorem Ipsum Donor</div>
																<div className="profile-overview">
																	<div className="profile-overview">
																		<div className="row text-center">
																			<div className="col-sm-4">
																				<h3>1</h3>
																				<p>Rank</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>50</h3>
																				<p>Matches</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>35</h3>
																				<p>Goals</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="tinder--card">
															<div className="profile-card-6">
																<img src="http://envato.jayasankarkr.in/code/profile/assets/img/profile-6.jpg" className="img img-responsive" />
																<div className="profile-name">JOHN
		                                                        <br />DOE</div>
																<div className="profile-position">Lorem Ipsum Donor</div>
																<div className="profile-overview">
																	<div className="profile-overview">
																		<div className="row text-center">
																			<div className="col-sm-4">
																				<h3>1</h3>
																				<p>Rank</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>50</h3>
																				<p>Matches</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>35</h3>
																				<p>Goals</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="tinder--card">
															<div className="profile-card-6">
																<img src="http://envato.jayasankarkr.in/code/profile/assets/img/profile-6.jpg" className="img img-responsive" />
																<div className="profile-name">JOHN
		                                                        <br />DOE</div>
																<div className="profile-position">Lorem Ipsum Donor</div>
																<div className="profile-overview">
																	<div className="profile-overview">
																		<div className="row text-center">
																			<div className="col-sm-4">
																				<h3>1</h3>
																				<p>Rank</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>50</h3>
																				<p>Matches</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>35</h3>
																				<p>Goals</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="tinder--card">
															<div className="profile-card-6">
																<img src="http://envato.jayasankarkr.in/code/profile/assets/img/profile-6.jpg" className="img img-responsive" />
																<div className="profile-name">JOHN
		                                                        <br />DOE</div>
																<div className="profile-position">Lorem Ipsum Donor</div>
																<div className="profile-overview">
																	<div className="profile-overview">
																		<div className="row text-center">
																			<div className="col-sm-4">
																				<h3>1</h3>
																				<p>Rank</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>50</h3>
																				<p>Matches</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>35</h3>
																				<p>Goals</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="tinder--card">
															<div className="profile-card-6">
																<img src="http://envato.jayasankarkr.in/code/profile/assets/img/profile-6.jpg" className="img img-responsive" />
																<div className="profile-name">JOHN
		                                                        <br />DOE</div>
																<div className="profile-position">Lorem Ipsum Donor</div>
																<div className="profile-overview">
																	<div className="profile-overview">
																		<div className="row text-center">
																			<div className="col-sm-4">
																				<h3>1</h3>
																				<p>Rank</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>50</h3>
																				<p>Matches</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>35</h3>
																				<p>Goals</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="tinder--card">
															<div className="profile-card-6">
																<img src="http://envato.jayasankarkr.in/code/profile/assets/img/profile-6.jpg" className="img img-responsive" />
																<div className="profile-name">JOHN
		                                                        <br />DOE</div>
																<div className="profile-position">Lorem Ipsum Donor</div>
																<div className="profile-overview">
																	<div className="profile-overview">
																		<div className="row text-center">
																			<div className="col-sm-4">
																				<h3>1</h3>
																				<p>Rank</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>50</h3>
																				<p>Matches</p>
																			</div>
																			<div className="col-xs-4">
																				<h3>35</h3>
																				<p>Goals</p>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>

													</div>

													<div className="tinder--buttons">
														<button id="nope"><i className="fa fa-remove"></i></button>
														<button id="love"><i className="fa fa-heart"></i></button>
													</div>
												</div>

											</div>
										</div>
									</div>
								</div>
							</div>



						</div>
					</div>
				</div>
			</div>


		</section>;
	}
}